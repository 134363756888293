.calendar-holder {
  width: 45%;
  display: flex;
  flex-direction: column;
  margin-bottom: 23px;
  color: white;
  font-family: "Industry-Book";
  font-size: 16px;
  position: relative;
  margin-right: 5%;
  @media (max-width: 991px) {
    width: 100%;
    margin-right: 0;
  }
  span {
    margin-bottom: 5px;
    font-size: 15px;
  }
  input {
    background-color: $eticket-gray;
    border: 1px solid $eticket-gray;;
    border-radius: 0;
    width: 100%;
    height: 43px;
    padding: 0 10px;
    color: white;
    appearance: none;
  }
  .input-with-icon {
    .react-datepicker-wrapper {
      width: 100%;
    }
    .icon {
      position: absolute;
      right: 15px;
      top: 35px;
    }
  }
  &.light {
    span {
      color: #262626;
      font-weight: bold;
    }
    input {
      background-color: transparent;
      border: 1px solid #E9E9E9;
      color: #262626;
    }
  }
}