.SCB-navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  // background: linear-gradient(180deg,rgba(0,0,0,1) 0%,rgba(0,0,0,1) 75%,rgba(0,0,0,0.8) 95%,rgba(0,0,0,0.7) 100%);
  background-color: $scb-black;
  padding-bottom: 10px;
  z-index: 100;

  & + .Page {
    padding-top: 100px;
  }

  @media (max-width: 991px) {
    height: 75px;
    background: #000000;

    & + .Page {
      padding-top: 125px;
    }
  }
  a {
    color: white;
  }
  .container, .row {
    height: 100%;
  }

  .header-meta {
    display: flex;
    justify-content: space-between;
    margin-left: 60%;
    padding-top: 15px;
    font-family: "Industry-Book";
    font-size: 14px;
    letter-spacing: 0.3px;
    color: white;
    @media (max-width: 991px) {
      display: none;
    }
  }
  .header-left, .header-right {
    flex: 1;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    letter-spacing: 0.5px;
    @media (max-width: 991px) {
      display: none;
    }
  }
  .header-logo {
    position: relative;
    margin: 0 30px;
    width: 140px;
    height: 70px;
    @media (max-width: 991px) {
      width: 50px;
      height: 100%;
    }
    img {
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
      border-radius: 100%;
      box-shadow: 0 -125px 65px 40px rgba(255,255,255,0.4);
      @media (max-width: 991px) {
        top: 12.5px;
      }
    }
  }
  .header-right-mob {
    position: absolute;
    top: 20px;
    right: 25px;
    @media (min-width: 992px) {
      display: none;
    }
    .button_toggle {
      width: 30px;
      height: 30px;
      background-color: transparent;
      border: none;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      position: relative;
      .button_toggle_line {
        width: 20px;
        height: 2px;
        background-color: white;
        transition: all 0.4s;
        &:nth-child(1) {
          transform: rotate(0);
        }
        &:nth-child(2) {
          opacity: 1;
        }
        &:nth-child(3) {
          transform: rotate(0);
          position: relative;
        }

      }
      &.close {
        .button_toggle_line {
          &:nth-child(1) {
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
            position: absolute;
            top: 9px;

          }
        }
      }
    }
  }
  .navigation-mob {
    position: absolute;
    left: 0;
    bottom: calc(-100vh + 75px);;
    width: 100%;
    height: 0;
    overflow: hidden;
    background-color: #000000;
    transition: all 0.4s;
    @media (min-width: 992px) {
      display: none;
    }
    &.show {
      height: calc(100vh - 75px);
    }
    .nav-mob {
      height: 100%;
      overflow-y: scroll;
      padding: 40px 20px 80px 20px;
      .nav-mob-main {
        display: flex;
        flex-direction: column;
        width: 100%;
        a {
          width: 98%;
          height: 60px;
          margin: auto;
          text-align: center;
          line-height: 60px;
          font-size: 25px;
          letter-spacing: 0.7px;
          border-bottom: 1px solid $scb-gray;
        }
      }
      .nav-mob-meta {
        width: 100%;
        font-family: "Industry-Book";
        font-size: 16px;
        letter-spacing: 0.3px;
        .row {
          width: 98%;
          height: 60px;
          margin: auto;
          border-bottom: 1px solid $scb-gray;
          justify-content: space-evenly;
          span {
            color: white;
          }
        }
      }
    }
  }
}
