* {
  box-sizing: border-box;
}

html, body {
  overflow: hidden;
  background-color: black;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@font-face {
  font-family: Industry-Black;
  src: url("./../assets/fonts/Industry-Black.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Industry-Book;
  src: url("./../assets/fonts/Industry-Book.otf");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Industry-Black";
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  &:focus, &:active {
    outline: none
  }
}

input, select {
  &:focus {
    outline: none;
  }
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  padding-left: 120px;
  @media (max-width: 991px) {
    padding-left: 0;
  }
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.add-row {
  width: 100%;
  max-width: 991px;
  @media (max-width: 991px) {
    max-width: 640px;
    margin: auto;
  }
  button {
    width: 80%;
    margin-top: 20px;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
}

.welcome-screen {
  width: 100%;
  max-width: 640px;
  margin-bottom: 50px;
  @media (max-width: 991px) {
    margin: auto;
  }
  button {
    width: 45%;
    margin-right: 5%;
    &.kep-login-facebook {
      border-radius: 5px;
      font-family: Industry-Black;
      padding: 10px 20px;
      font-size: 16px;
    }
    @media (max-width: 991px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}

.col {
  flex: 1;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  @media (max-width: 991px) {
    justify-content: flex-start;
    button {
      margin: 20px 0 0 0;
    }
  }
}

.spec-title {
  font-family: "Industry-Black";
}

input[type=radio] {
  visibility: hidden;
  position: absolute;
}
input[type=radio] + label:before {
  height: 13px;
  width: 13px;
  margin-right: 10px;
  content: "";
  display: inline-block;
  vertical-align: middle;
  border: 2px solid white;
  border-radius: 50%;
}
input[type=radio]:checked + label:before {
  background: white;
  text-align: center;
}
