.link-holder {
  margin: 10px 0;
  .Link {
    font-family: Industry-Book;
    font-size: 16px;
    letter-spacing: 1px;
    cursor: pointer;
    &.dark {
      color: $scb-red;
    }
    &.light {
      color: #333333;
    }
  }

}