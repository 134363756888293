.Container {
  position: relative;
  display: flex;
  flex: 1;
  max-width: 1200px;
  width: 100%;
  min-height: 600px;
  margin: 0 auto;
  padding: 0 15px;
  padding-left: 0;

  @media (max-width: 991px) {
    padding-left: 15px;
  }

  &-Side {
    width: 300px;
    min-width: 300px;
    border-right: 1px solid white;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    @media (max-width: 991px) {
      min-width: 100%;
      border-right: none;
      transition: all 0s ease-in-out;
    }

    &.nospace {
      padding-left: 0;
      padding-right: 0;
    }

    &.hide {
      width: 0;
      min-width: 0;
      border-right: none;
    }

    &.hideOnMob {
      @media (max-width: 991px) {
        width: 0;
        min-width: 0;
        border-right: none;
      }
    }
    .Container-Side-Content {
      width: 300px;
      padding: 50px 20px;
      overflow: hidden;
      @media (max-width: 991px) {
        width: 100%;
        padding: 35px 15px;
      }
    }

    & + .Container-body {
      padding-left: 100px;
      padding-bottom: 50px;
      min-height: 400px;
      @media (max-width: 991px) {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        min-height: unset;
      }
    }
  }

  &-SideMenu {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    left: -120px;
    bottom: 0;
    border-right: 1px solid white;
    background-color: black;
    z-index: 99;

    @media (max-width: 991px) {
      position: fixed;
      top: 75px;
      right: 0;
      left: 0;
      flex-direction: row;
      justify-content: space-around;
      bottom: unset;
      border-right: none;
      border-top: 1px solid white;
      border-bottom: 1px solid white;

      & > a {
        flex: 1;
      }
    }

    &-Icon {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 100px;
      @media (max-width: 991px) {
        display: none;
      }

      &::after {
        content: "";
        position: absolute;
        width: 80px;
        height: 1px;
        bottom: -1px;
        background-color: white;
        z-index: 99;
      }

      &:hover {
        background-color: rgba(255, 0, 0, 0.5);
      }

      & > img {
        width: 36px;
      }

      & > span {
        margin: 0;
        margin-top: 8px;
        padding: 0;
        font-size: 16px;
        color: white;
      }

      &.selected {
        background-color: $scb-red;

        &::after {
          height: 4px;
          bottom: -2px;
        }
      }
    }
    &-Icon-Mob {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 5px;
      height: 50px;
      @media (min-width: 992px) {
        display: none;
      }

      & > img {
        width: 35px;
      }
    }
  }

  &-body {
    flex: 1;
    padding: 50px 0 0 170px;
    @media (max-width: 991px) {
      padding: 35px 0;
    }
  }

  &.nospace {
    .Container-Side,
    .Container-body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
