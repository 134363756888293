.Info {
  margin-bottom: 40px;

  &-row {
    padding: 5px 0;

    & > label,
    & > p {
      margin: 5px 0;
      padding: 0;
      color: white;
      font-size: 16px;
      font-family: 'Industry-Book';
    }

    & > label {
      font-weight: bold;
    }

    & > p {
      font-weight: normal;
    }
  }
}
