.TicketCodeInput {
  display: flex;
  margin: 10px -3px;

  &-Input {
    position: absolute;
    height: 0;
    overflow: hidden;
    opacity: 0;
    z-index: -1;
  }

  &-Field {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $scb-red;
    border: 1px solid white;
    border-radius: 5px;
    width: 24px;
    height: 24px;
    font-size: 16px;
    font-family: 'Industry-Book';
    margin: 0 3px;
    cursor: pointer;

    &.active {
      border-color: $scb-red;
    }
  }

  &-separator {
    display: inline-block;
    width: 10px;
    margin-top: 12px;
    border-top: 1px solid white;
  }
}
