.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}
.Modal {
  &-Header {
    display: flex;
    margin-bottom: 30px;

    &-title {
      flex: 1;
      margin: 0;
      padding: 0;
      color: black;
      font-size: 20px;
    }

    &-closebutton {
      color: black;
      font-size: 25px;
      cursor: pointer;
    }
  }

  &-Body {
    .form-container form {
      width: 100%;
    }
  }
}