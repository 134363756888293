.Alert {
  &-message {
    margin: 0;
    padding: 0;
    font-family: 'Industry-Book';
  }

  &-buttoncontainer {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;

    & > .Button {
      width: 45% !important;
    }
  }
}
