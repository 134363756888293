.VoucherInfo {
  span.info {
    display: block;
    margin-top: 5px;
    font-size: 14px;
    font-family: 'Industry-Book';

    & > svg {
      margin-left: 15px;
      margin-right: 5px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  img.image {
    width: 50%;

    @media (max-width: 991px) {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }
}