.HeaderTitle {
  display: flex;
  align-items: center;
  width: calc(100% + 120px);
  margin-left: -120px;
  padding-left: 120px;
  height: 240px;
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 991px) {
    display: none;
  }

  h1 {
    margin: 0;
    padding: 0;
    color: white;
    font-size: 36px;
    text-shadow: 0 0 5px black;
  }
}
