.DatePicker {
  position: relative;
  display: flex;
  width: 100%;
  border: 1px solid transparent;

  &.error {
    border-color: red;
  }

  & > .select-holder {
    width: auto;
    flex: 1.8;
    margin-bottom: 0;
    border-left: 1px solid gray;

    &.light {
      border: none;
    }

    & > select {
      background-image: none !important;
      padding-right: 0;
    }

    &:first-child {
      flex: 1.2;
      border: 0;
    }

    &:last-child {
      flex: 1.5;
      border: none;
    }
  }

  &-clearbutton {
    position: absolute;
    width: 20px;
    height: 20px;
    padding: 2px;
    right: 13px;
    top: 12.5px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: gray;
    cursor: pointer;
    z-index: 10;

    &.hide {
      display: none;
    }
  }
}
