.SquarePasses {
  &-Row {
    display: flex;
    border-left: 1px solid white;
    margin-top: -1px;

    &:first-child {
      margin-top: 0;
    }
  }

  &-Item {
    position: relative;
    width: 16.65%;
    min-width: 130px;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    background-color: black;
    overflow: hidden;

    &::after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    &.disabled {
      position: relative;
      cursor: initial !important;

      h5.footerlabel {
        background-color: gray;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: black;
        opacity: 0.65;
        z-index: 10;
      }
    }

    &.edit {
      background-color: $scb-red;
      cursor: pointer;

      &.lottery {
        background-color: $scb-red;

        h4.editlabel {
          color: white !important;
        }
      }

      &.disabled {
        background-color: #424242;
        cursor: initial;

        & > * {
          opacity: 0.5;
        }
      }
    }

    &.user {
      background-color: #424242;

      .SquarePasses-Item-body {
        align-items: flex-start;
        padding: 10px;
      }
    }

    &.invite {
      background-color: #424242;

      .SquarePasses-Item-body {
        justify-content: center;
        color: white;
        cursor: pointer;
      }
    }

    &.lottery {
      background-color: white;

      & * {
        color: black !important;
      }

      &.wait {
        background-color: $lottery;

        & * {
          color: white !important;
        }
      }

      &.pointer {
        cursor: pointer;
      }

      .SquarePasses-Item-footer span {
        color: black;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      &.middle {
        justify-content: center;
      }

      & > img.logo {
        width: 32px;
        margin-top: 10px;
        margin-bottom: 5px;
      }

      & > h3 {
        margin: 0;
        padding: 0;
        color: white;
        text-align: center;
        font-size: 18px;
      }

      & > span.token, & > span.type {
        color: white;
        text-align: center;
        font-size: 16px;
        font-family: 'Industry-Book';
      }

      & > img.clublogo {
        width: 32px;
        margin-top: 10px;
        margin-bottom: 5px;
      }

      & > span.date {
        color: white;
        font-size: 16px;
        font-family: 'Industry-Book';
      }

      & > span.stadium {
        color: white;
        font-size: 14px;
        font-family: 'Industry-Book';
        text-align: center;
      }

      & > img.notavailable {
        width: 96px;
      }

      & > h2.games {
        margin: 0;
        padding: 0;
        color: white;
      }

      & > img.editcontacticon {
        width: 48px;
        margin-bottom: 10px;
      }

      & > h4.editlabel {
        margin: 0;
        padding: 0;
        color: white;
        font-size: 14px;
        text-align: center;
      }

      & > h3.name {
        color: #BDBDBD !important;
        margin: 0;
        padding: 0;
        color: white;
        font-size: 16px;
        text-align: left;
      }

      & > span.phone {
        color: #BDBDBD;
        font-size: 14px;
        font-family: 'Industry-Book';
      }

      & > span.link {
        width: 100%;
        color: #BDBDBD;
        font-size: 14px;
        font-family: 'Industry-Book';
        overflow-wrap: break-word;
      }
    }
    &-ETicket {
      color: white;
      font-family: 'Industry-Book';
      opacity: 0.65;
      font-size: 14px;
      position: absolute;
      bottom: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.bottom {
        bottom: 7px;
      }
      svg {
        margin-right: 5px;
      }
      &:hover {
        opacity: 1;
      }
    }

    &-footer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.65;
      padding-left: 5px;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      &.nohover {
        opacity: 1;
        cursor: initial;
      }

      & > img {
        width: 16px;
        margin-right: 5px;
      }

      & > span {
        color: white;
        font-size: 14px;
        font-family: 'Industry-Book';
        display: inline-block;
        padding-bottom: 2px;
      }

      h5.footerlabel {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -7px;
        margin: 0;
        padding: 5px 0;
        color: white !important;
        font-size: 12px;
        text-align: center;
        background-color: $lottery;
      }
    }

    &-icon {
      position: absolute;
      right: 10px;
      bottom: 7px;
      display: flex;
      justify-content: flex-end;
      color: white;
      opacity: 0.65;
    }
  }
}
