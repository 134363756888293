.map-view {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  .map-wrapper {
    width: 100%;
    max-width: 991px;
    margin: auto;
    height: auto;
    position: relative;
    img {
      width: 100%;
    }
    .close{
      color: #333333;
      font-size: 25px;
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}