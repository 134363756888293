.Page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 50px);
  background-color: black;
  padding-left: 120px;
  overflow: hidden;
  @media (max-width: 991px) {
    padding-left: 0;
  }
}
