.form-container {
  width: 100%;
  max-width: 991px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 991px) {
    align-items: stretch;
    max-width: 640px;
    padding: 0;
    margin: auto;
  }
  form {
    width: 80%;
    @media (max-width: 991px) {
      width: 100%;
    }
    .row {
      justify-content: flex-start;
      @media (max-width: 991px) {
        flex-direction: column;
        align-items: center;
      }
      .input-wrap {
        &:nth-child(1) {
          margin-right: 5%;
          @media (max-width: 991px) {
            margin-right: 0;
          }
        }
        &:nth-child(2) {
          margin-left: 5%;
          @media (max-width: 991px) {
            margin-left: 0;
          }
        }
      }
      button {
        width: 45%;
        @media (max-width: 991px) {
          width: 100%;
        }
        &:nth-child(1) {
          margin-right: 5%;
          @media (max-width: 991px) {
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
        &:nth-child(2) {
          margin-left: 5%;
          @media (max-width: 991px) {
            margin-left: 0;
          }
        }
      }
    }
  }
}