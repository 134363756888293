.ticket-view {
  width: 100%;
  max-width: 991px;
  margin: 0 auto;
  min-height: calc(100vh - 105px);
  display: flex;
  flex-direction: column;
  .close-button {
    color: $eticket-yellow;
    font-size: 25px;
    align-self: flex-end;
    margin-right: 20px;
    margin-bottom: 15px;
  }
  .no-info {
    color: white;
    padding: 0 15px;
    margin-bottom: 100px;
    p {
      font-family: "Industry-Black";
      text-transform: uppercase;
      text-align: center;
      padding: 30px 50px;
      background-color: $eticket-red;
    }
    button {
      width: 90%;
      height: 50px;
      display: block;
      margin: auto;
      background-color: transparent;
      border: 1px solid $eticket-yellow;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        width: 200px;
        display: inline-block;
        font-size: 15px;
        text-align: center;
        line-height: 22px;
      }
    }
  }
  .ticket-details {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .seat-details,
    .game-details,
    .transport-info {
      width: calc(50% - 5px);
      height: 220px;
      background-color: $eticket-gray;
      color: white;
      padding: 15px;
      font-family: Industry-Book;
      h3 {
        color: $eticket-yellow;
        font-size: 20px;
        position: relative;
        font-family: "Industry-Black";
        &::before {
          content: "";
          width: 55px;
          height: 2px;
          background-color: $eticket-yellow;
          position: absolute;
          top: -10px;
        }
      }
      p {
        margin: 0;
      }
      .map-btn-row {
        text-align: right;
        margin-top: 20px;
        button {
          border: none;
          background-color: transparent;
          color: $eticket-yellow;
          font-size: 25px;
          padding: 0;
        }
      }
    }

    .transfer-alert {
      margin: 20px;
      padding: 20px;
      width: 100%;
      color: white;
      background-color: #E2091A;

      & > h3 {
        margin: 0;
        padding: 0;
        text-align: center;
        text-transform: uppercase;
      }

      & > p {
        margin: 20px 0;
        padding: 0;
        font-family: Industry-Book;
        text-align: center;
      }

      & > a {
        position: relative;
        display: block;
        width: 100%;
        padding: 15px;
        color: white;
        border: 1px solid white;
        text-align: center;
        text-transform: uppercase;

        &:hover {
          background-color: rgba(255, 255, 255, 0.25);
        }

        & > svg {
          position: absolute;
          right: 15px;
          top: 0;
          height: 100%;
        }
      }
    }

    .transport-info {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
  }
}