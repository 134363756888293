.InputFile {
  position: absolute;
  left: 0;
  right: 0;
  height: 41px;
  background-color: $eticket-gray;
  display: flex;
  flex-direction: row;

  & > span {
    flex: 1;
    color: white;
    padding: 0 10px;
    font-family: "Roboto-Condensed";
    font-size: 16px;
    line-height: 40px;
    letter-spacing: 1px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  & > button,
  & > label {
    width: 40px;
    height: 40px;
    margin: 1px;
    border: none;
    background-color: $scb-red;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled,
    &.disabled {
      opacity: 0.5;
    }
  }
}
