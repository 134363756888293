.Loader {
  display: flex;
  justify-content: center;

  &::after {
    content: "";
    box-sizing: border-box;
    display: block;
    border: 8px solid rgba(255, 255, 255, 0.5);
    border-top-color: white;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 2s linear infinite;
    display: flex;
    align-self: center;
  }

  &.dark {
    &::after {
      border-color: rgba(0, 0, 0, 0.35);
      border-top-color:  black;
    }
  }

  &.big {
    &::after {
      width: 48px;
      height: 48px;
      border-width: 12px;
    }
  }
}
