.input-wrap {
  width: 45%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  color: white;
  font-family: "Industry-Book";
  font-size: 16px;
  position: relative;
  @media (max-width: 991px) {
    width: 100%;
  }
  .label {
    margin-bottom: 5px;
    font-size: 16px;
  }
  input {
    background-color: $eticket-gray;
    border: 1px solid transparent;
    border-radius: 0;
    width: 100%;
    height: 43px;
    padding: 0 10px;
    color: white;
    font-family: "Industry-Book";
    font-size: 16px;
    letter-spacing: 1px;
    transition: all 0.4s ease-in-out;
    appearance: none;
    &.error {
      border: 1px solid $scb-red;
    }
    &.disabled {
      opacity: 0.5;
    }
  }
  .error-msg {
    height: 10px;
    font-size: 12px;
    color: $scb-red;
    margin: 3px 0 0;
    p {
      margin: 0;
    }
  }
  .icon {
    position: absolute;
    right: 15px;
    top: 34px;
    img {
      width: 25px;
      height: auto;
    }
  }
  &.fullwidth {
    width: 100%;
  }
  &.light {
    span {
      color: #262626;
      font-weight: bold;
    }
    input {
      background-color: transparent;
      border: 1px solid #E9E9E9;
      color: #262626;
    }
  }
}