.help-info {
  background: rgba(216, 35, 42, 0.1);
  border: 1px solid $scb-red;
  border-radius: 5px;
  color: white;
  padding: 15px;
  margin-bottom: 15px;
  @media (max-width: 991px) {
    margin-bottom: 0;
  }
  p {
    margin: 0;
    font-size: 15px;
    display: inline;
    letter-spacing: 0.3px;
    text-align: justify;
  }
}