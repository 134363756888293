.ticket-checked-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-bottom: 10px;
  .qr-code {
    width: 245px;
    height: 245px;
    margin: 20px;
    background-color: white;
    img {
      width: 100%;
    }
  }
  a.access-token {
    color: white;
    color: black;
    text-decoration: underline;
    margin-bottom: 20px;

    &:hover {
      color: $scb-red;
    }
  }
  .guest-details {
    width: 100%;
    background-color: $eticket-gray;
    margin-top: 40px;
    margin-bottom: 5px;
    padding: 15px;
    color: white;
    font-family: "Industry-Book";
    h3 {
      font-size: 25px;
      font-family: "Industry-Black";
      text-transform: uppercase;
      margin: 0;
      font-weight: 400;
      line-height: 40px;
      &.ticket-type {
        color: $eticket-yellow;
      }
    }
    p {
      margin: 0;
    }
    .guest-details-bottom {
      display: flex;
      align-items: center;
      margin-top: 5px;

      & > a {
        margin-right: 10px;
      }

      .edit-btn {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        button {
          background-color: transparent;
          border: none;
          color: white;
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
          }
        }
      }
      a {
        color: $eticket-yellow;
      }
    }
  }
  .ticket-used {
    width: 100%;
    padding: 15px;
    font-family: "Industry-Book";
    .used {
      width: 100%;
      height: 50px;
      color: white;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &.yes {
        background: rgba(52, 183, 100, 0.5);
        color: #34B764;
      }
      &.no {
        background: rgba(226, 0, 26, 0.5);
        color: #E2001A;
      }
      svg {
        margin-left: 30px;
        font-size: 20px;
      }
      span {
        flex: 1;
        text-align: center;
        margin-left: -30px;
      }
    }
    p {
      color: white;
      text-align: center;
      margin: 0;
      font-size: 15px;
      margin-top: 5px;
    }
    .feedback {
      margin: 20px 0;
      background-color: $eticket-yellow;
      color: #333333;
      padding: 30px 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      h3 {
        text-align: center;
      }
      span {
        text-align: center;
        margin-bottom: 20px;
      }
      button {
        width: 170px;
        height: 50px;
        background-color: black;
        border: none;
        color: white;
      }
    }
  }
}

@media print {
  body, .external-page {
    background-color: white !important;
  }

  .external-page-container .header img:nth-child(2) {
    background-color: #000 !important;
    padding: 5px 10px;
    border-radius: 10px;
  }

  .external-page-container .header img:nth-child(1) {
    width: 75px;
    height: 75px;
  }

  .ticket-view .ticket-details .game-details h3, .ticket-view .ticket-details .seat-details h3, .ticket-view .ticket-details .transport-info h3 {
    color: #444;
  }

  .ticket-view .ticket-details .game-details p, .ticket-view .ticket-details .seat-details p, .ticket-view .ticket-details .transport-info p {
    color: #000;
  }

  .ticket-view .ticket-details .game-details, .ticket-view .ticket-details .seat-details, .ticket-view .ticket-details .transport-info {
    background-color: #eee !important;
    -webkit-print-color-adjust: exact;
  }
  .ticket-view .ticket-details .game-details h3:before, .ticket-view .ticket-details .seat-details h3:before, .ticket-view .ticket-details .transport-info h3:before {
    background-color: #000 !important;
  }

  .ticket-view .close-button {
    display: none;
  }
  .ticket-checked-info {
    flex: 1;
    width: 50%;
    margin-left: 50%;
    padding-top: 50px;
  }
  .access-token{
    color: black !important;
  }
}