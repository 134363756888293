.select-holder {
  width: 45%;
  display: flex;
  flex-direction: column;
  margin-bottom: 23px;
  color: white;
  font-family: "Industry-Book";
  font-size: 16px;
  position: relative;
  @media (max-width: 991px) {
    width: 100%;
  }
  span {
    margin-bottom: 5px;
    font-size: 16px;
  }
  select {
    background-color: $eticket-gray;
    border: none;
    border-radius: 0;
    width: 100%;
    height: 45px;
    padding: 0 10px;
    color: white;
    font-family: "Industry-Book";
    font-size: 16px;
    letter-spacing: 1px;
    -webkit-appearance: none;
    background-image: url("./../../assets/images/icons/arrow-down.png");
    background-position: calc(100% - 13px) 17px;
    background-repeat: no-repeat;
    padding-right: 37px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    option {
      background-color: #616365;
      color: #A9AEB2;
    }
  }
  &.marked {
    opacity: 0.35;
  }
  &.light {
    span {
      color: #262626;
      font-weight: bold;
    }
    select {
      background-color: transparent;
      border: 1px solid #E9E9E9;
      color: #262626;
      background-image: url("./../../assets/images/icons/arrow-down-dark.png");
      option {
        background-color: transparent;
        color: #262626;
      }
    }
  }
}