.Button {
  position: relative;
  background-color: $scb-red;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  color: white;
  font-family: Industry-Black;
  min-height: 40px;
  font-size: 16px;
  letter-spacing: 1px;
  overflow: hidden;

  @media (max-width: 991px) {
    margin: auto;
    display: block;
  }

  &:disabled {
    opacity: 0.5;
    cursor: unset;
  }

  &.fullwidth {
    width: 100%;
  }

  &.dark {
    background-color: black;
  }

  &.light {
    color: black;
    background-color: lightgray;
  }

  &.gray {
    color: white;
    background-color: $scb-gray;
  }

  &.margin-top {
    margin-top: 20px;
  }

  &.margin-bottom {
    margin-bottom: 20px;
  }

  &.margin-vertical {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &.loader {
    & > span {
      opacity: 0;
    }
  }

  .icon {
    position: absolute;
    left: 10px;
    top: 50%;
    width: 20px;
    transform: translateY(-50%);

    & + span {
      margin-left: 19px;
      display: inline-block;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
