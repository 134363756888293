.Pass {
  margin-bottom: 40px;
  background-color: #e9e9e9;

  &-header {
    display: flex;
    padding: 20px 30px;
    background-color: #212121;


    & > img {
      width: 80px;
      height: 80px;
      margin-right: 30px;
      @media (max-width: 991px) {
        width: 50px;
        height: 50px;
      }
    }

    &-text {
      display: flex;
      flex: 1;
      padding-top: 10px;
      justify-content: center;
      @media (max-width: 991px) {
        flex-direction: column;
      }

      &-info {
        display: flex;
        @media (max-width: 991px) {
          flex-direction: column;
        }

        &-datetime {
          font-family: 'Industry-Book';
          color: white;
          padding-right: 20px;

          & > span {
            display: block;
          }
        }

        &-place {
          font-family: 'Industry-Book';
          font-weight: lighter;
          color: white;

          & > span {
            display: block;
          }
        }
      }
    }

    h2.title {
      flex: 1;
      margin: 0;
      padding: 0;
      padding-right: 20px;
      color: white;
      @media (max-width: 991px) {
        font-size: 18px;
        flex: unset
      }
    }
  }

  &-section {
    display: flex;
    border-top: 1px solid lightgray;
    @media (max-width: 991px) {
      display: none;
    }

    & > span {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      color: white;
      height: 50px;
      background-color: #262626;

      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {
        width: 130px;
        flex: none;
        border-right: 1px solid lightgray;
      }

      &:nth-child(2) {
        width: 180px;
      }
    }
  }

  &-Item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid darkgray;
    font-family: 'Industry-Book';

    &-details {
      flex: 1;
      display: flex;
      min-height: 50px;

      @media (max-width: 991px) {
        flex-direction: column;
      }
    }

    &-filed {
      display: flex;
      align-items: center;
      width: 130px;
      padding: 5px 20px;
      border-right: 1px solid darkgray;
      font-weight: bold;

      &:nth-child(1), &:nth-child(3) {
        @media (max-width: 991px) {
          display: none;
        }
      }

      &:nth-child(2) {
        width: 180px;
        @media (max-width: 991px) {
          width: 100%;
          border-right: none;
        }
      }
    }

    &-name {
      flex: 1;
      padding-left: 20px;
      font-weight: bold;
      display: flex;
      align-items: center;
      @media (max-width: 991px) {
        height: 30px;
        flex: unset;
      }

      & > span {
        margin-left: 10px;
        font-weight: normal;
      }
    }

    &-ETicket {
      margin-right: 1px;
    }

    &-edit,
    &-scanned,
    &-lottery,
    &-ETicket {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      width: 50px;
      color: white;
      background-color: $scb-red;
      cursor: pointer;

      &.disabled {
        cursor: initial;
        background-color: $scb-gray;

        & > * {
          opacity: 0.5;
        }
      }

      & > img {
        width: 24px;
      }
    }

    &-edit {
      &.lottary {
        background-color: $lottery;
      }
    }

    &-scanned {
      background-color: #424242;
      cursor: initial;
    }

    &-lottery {
      background-color: $lottery;

      &.inactive {
        background-color: $scb-gray;
        cursor: initial;
      }
    }
  }
}
