.Paragraph {
  margin: 20px 0;
  padding: 0;
  color: white;
  font-size: 18px;
  line-height: 22px;
  font-family: 'Industry-Book';
  &.light {
    color: #333333;
  }
  @media (max-width: 991px) {
    text-align: center;
    font-size: 16px;
  }
}
