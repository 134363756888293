.external-page {
  width: 100%;
  min-height: 100vh;
  background-color: $eticket-dark;
  .external-page-container {
    width: 100%;
    max-width: 991px;
    margin: 0 auto;
    padding: 0 15px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
  }
}