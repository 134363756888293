.checkbox-holder {
  margin-bottom: 20px;
  font-family: "Industry-Book";
  align-self: flex-start;
  display: flex;

  .checkbox {
    position: relative;
    display: flex;
    align-items: flex-start;
    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      width: 21px;
      min-width: 21px;
      height: 21px;
      background-color: $eticket-gray;
      border-radius: 0;
      border: none;
      &.error {
        border: 1px solid $scb-red;
      }
    }
  }
  .checkbox-text {
    span {
      color: white;
      margin-left: 5px;
      font-size: 14px;
      line-height: 25px;
      &.link {
        color: $scb-red;
        cursor: pointer;
      }
    }
  }
  .checkbox > input:checked + span::before {
    content: '\2713';
    display: block;
    color: #A9AEB2;
    font-size: 18px;
    position: absolute;
    left: 0.5rem;
    top: 3px;
    font-weight: bold;
  }
  &.light {
    input {
      background-color: transparent;
      border: 1px solid #E9E9E9;
      color: #262626;
    }
    span {
      color: #262626;
      font-weight: bold;
    }
  }
}