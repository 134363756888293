.Title {
  margin-bottom: 50px;
  
  &-title,
  &-subtitle {
    margin: 0;
    padding: 0;
    color: white;
  }

  &-title {
    font-size: 32px;
  }

  &-subtitle {
    font-size: 18px;
    font-weight: normal;
    font-family: 'Industry-Book';
  }

  &.side {
    .Title-title {
      color: $scb-red;
    }
  }
  &.light {
    .Title-title {
      color: #333333;;
    }
  }



  @media (max-width: 991px) {
    margin-bottom: 10px;
    &-title,
    &-subtitle {
      text-align: center;
    }
    &-title {
      font-size: 24px;
    }
  }

  &::after {
    content: "";
    display: block;
    width: 100px;
    height: 3px;
    margin-top: 10px;
    background-color: $scb-red;
    @media (max-width: 991px) {
      display: none;
    }
  }

  &.small {
    margin-bottom: 30px;

    .Title-title {
      font-size: 20px;
    }

    .Title-subtitle {
      font-size: 16px;
    }

    &::after {
      display: none;
    }
  }
}
