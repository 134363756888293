.ErrorMessages {
  padding: 10px 0;

  & > p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Industry-Book';
    color: $scb-red;
    letter-spacing: 1px;
  }
}
