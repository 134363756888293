.InputImage {
  position: absolute;
  right: 0;
  height: 41px;
  background-color: $eticket-gray;
  display: flex;
  flex-direction: row;

  & > label {
    width: 40px;
    height: 40px;
    margin: 1px;
    border: none;
    background-color: $scb-red;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &.disabled {
      opacity: 0.5;
    }
  }

  & > input {
    display: none;
  }
}
