.AnonymousLink {
  position: relative;
  margin-bottom: 25px;

  &-copy {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: $scb-red;
    cursor: pointer;
  }

  & > .input-wrap {
    width: 100%;

    input {
      padding-right: 110px;
    }
  }

  & > button {
    width: 100%;
  }
}
