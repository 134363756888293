.ContactList {
  margin-bottom: 60px;

  .Item {
    position: relative;
    display: flex;
    margin: 0 -30px;
    padding: 15px 30px;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 30px;
      right: 30px;
      height: 1px;
      background-color: white;
      margin-top: -1px;
    }

    &:last-child::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 30px;
      right: 30px;
      height: 1px;
      background-color: white;
      margin-bottom: -1px;
    }

    &:hover {
      background-color: rgba(255, 0, 0, 0.25);
    }

    &.selected {
      background-color: $eticket-red;
    }

    &-profile {
      width: 36px;
      height: 36px;
      margin: 5px 0;
      margin-right: 15px;
      border-radius: 50%;
      object-fit: cover;
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-name,
      &-phone {
        margin: 1px 0;
        padding: 0;
        font-size: 16px;
        font-family: 'Industry-Book';
      }

      &-name {
        color: white;
        font-weight: bold;
      }

      &-phone {
        color: white;
        font-weight: normal;
      }
    }

    &-edit {
      position: absolute;
      width: 16px;
      height: 16px;
      bottom: 10px;
      right: 30px;

      &.disabled {
        opacity: 0.5;
      }
    }
  }
}
