.Switcher {
  display: flex;
  // @media (max-width: 991px) {
  //   display: none;
  // }

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }

  &-Button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: darkgray;
    cursor: pointer;
    @media (max-width: 991px) {
      width: 35px;
      height: 35px;
    }

    &.active {
      background-color: white;
    }

    &-icon {
      width: 32px;
      height: 32px;
      @media (max-width: 991px) {
        width: 25px;
        height: 25px;
      }
    }
  }
}
