.PhoneNumber {
  display: flex;
  width: 100%;
  border: 1px solid transparent;

  &.light {
    border: 1px solid #E9E9E9;

    span.code {
      background-color: transparent;
      border-right-color: #E9E9E9;
    }
  }

  &.error {
    border-color: red;
  }

  &-Selectbox {
    position: relative;

    & > span.code {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0;
      padding: 0;
      padding-right: 10px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $eticket-gray;
      border-right: 1px solid gray;

      &.disabled {
        opacity: 0.5;
      }
    }

    & > .select-holder {
      width: 55px;
      margin-bottom: 0;

      &.light {
        border: none;
      }

      & > select {
        background-image: none !important;
        padding-right: 0;
        height: 45px;
        opacity: 0;
      }
    }
  }

  & > .input-wrap {
    flex: 1;
    margin-left: 0 !important;
    margin-bottom: 0;

    & > input {
      width: 100%;
      height: 45px;
    }

    &.light > input {
      border: none;
    }

    & > .error-msg {
      display: none;
    }
  }
}
