.SCB-footer {
  width: 100%;
  height: 50px;
  background-color: #222222;
  color: white;
  font-size: 14px;
  font-family: Industry-Book;
  @media (max-width: 991px) {
    height: unset;
    padding: 20px 0;
  }
  .container {height: 100%;}
  .meta {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 991px) {
      flex-direction: column-reverse;
    }
    .right, .left {
      flex: 1;
      display: flex;
    }
    .left {
      span {
        margin-right: 3px;
      }
      @media (max-width: 991px) {
        margin-top: 5px;
        flex-direction: column;
        align-items: center;
        span {
          margin-top: 10px;
          &:nth-child(2) {
            display: none;
          }
        }
      }
    }
    .right {
      justify-content: flex-end;
      a {
        color: white;
        margin-left: 50px;
        @media (max-width: 991px) {
          margin-left: 15px;
        }
      }
    }
  }
}